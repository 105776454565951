<template>
  <div class="page-box box">
    <div class="content-box box">
      <canvas id="pdf-canvas"></canvas>
      <template v-if="imgs && imgs.length > 0">
        <div class="tip">
          可点击图片进行预览
        </div>
        <img
          v-for="(item, index) in imgs"
          :key="index"
          :src="item"
          alt=""
          @click="imagePreview(imgs)"
        >
      </template>

      <!-- code 码 -->
      <van-overlay
        style="background: rgba(0,0,0,0.3)"
        :show="show"
        @click="show = false"
      >
        <div
          class="wrapper-box"
          @click.stop="show = false"
        >
          <div
            class="block-box"
            @click.stop
          >
            <div class="text-a">
              请填写手机短信验证码
            </div>
            <div class="text-b">
              已发送到{{ orderInfo.mobile || '' }}
            </div>
            <van-field
              v-model="code"
              maxlength="6"
              class="text-c"
              label="验证码"
            />
            <van-button
              class="code-btn"
              color="#51B464"
              round
              type="info"
              @click="confirm"
            >
              提交
            </van-button>
          </div>
        </div>
      </van-overlay>
      
      <van-button
        class="_btn"
        color="#51B464"
        round
        type="info"
        @click="sendCode"
      >
        确认签署申请
      </van-button>
    </div>
  </div>
</template>


<script>
import { ImagePreview } from 'vant'
export default {
  name: 'Sign',
  components: {
  },
  data () {
    return {
      orderInfo: {
        contractUrl: ''
      },
      imgs: [],
      pageNum: 1,
      pdf: null,
      show: false,
      code: '',
      pagetype: '',
      apiStr: 'orderApi'
    }
  },
  computed: {
  },
  async created () {
    this.$toast.loading({
      message: '加载中',
      forbidClick: true,
      duration: 0
    });
    this.pageType = this.$route.query.type || ''
    if (this.pageType === 'orgSettled' || this.pageType === 'orgPeriod' || this.pageType === 'contract' || this.pageType === 'bank') {
      this.apiStr = 'orgApi'
    }

    await this.getPdfjs()
    this.orderInfo = JSON.parse(window.localStorage.getItem('pdfOrder') || '{}')
    this.getContent()
  },
  methods: {
    async getPdfjs() {
      return new Promise((resolve, reject) => {
          let script = document.createElement("script");
          script.type = "text/javascript";
          script.src = 'https://img.tjerong.cn/js/pdf.min.js'
          script.onload = () => {
            console.log('测试', pdfjsLib)
            resolve(pdfjsLib)
          }
          script.onerror = () => {
            this.$toast.clear()
            reject()
          };
          document.head.appendChild(script);
      });
    },
    async sendCode () {
      this.$toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      });
      let url = 'orderSendCode'
      let data = {id: this.$route.query.id || ''}
      if (this.pageType === 'orgSettled') {
        url = 'orgSmsSend'
      } else if (this.pageType === 'orgPeriod') {
        url = 'orgPeriodSmsSend'
      } else if (this.pageType === 'contract') {
        url = 'contractSmsSend'
        data.orderNo = this.$route.query.orderNo
      } else if (this.pageType === 'bank') {
        url = 'bankSmsSend'
      }
      let res = await this.$http[this.apiStr][url](data)
      if (res.code === 200) {
        this.show = true
        this.$toast.clear()
        this.$toast('验证码已发送')
      }
    },
    async confirm () {
      if (!this.code) {
        this.$toast('请填写验证码')
        return
      }
      this.$toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      });
      let data = {
        orderNo: this.$route.query.id || '',
        smsCode: this.code
      }
      let url = 'orderConfirm'

      if (this.pageType === 'orgSettled') {
        url = 'orgSettledConfirm'
      } else if (this.pageType === 'orgPeriod') {
        url = 'orgPeriodConfirm'
      } else if (this.pageType === 'contract') {
        url = 'contractConfirm'
        data.id = this.$route.query.id || ''
        data.orderNo = this.$route.query.orderNo
      } else if (this.pageType === 'bank') {
        url = 'bankConfirm'
      }
      let res = await this.$http[this.apiStr][url](data)
      if (res.code === 200) {
        this.$toast.clear()
        this.$toast('签署成功')
        if (this.pageType === 'bank') {
          window.localStorage.removeItem('orderInfo')
          window.localStorage.removeItem('courseDetail')
          window.localStorage.removeItem('userCheckInfo')
          window.localStorage.removeItem('faceOrderNo')
          setTimeout(() => {
            let backlen = history.length-2;
            history.go(-backlen);
          }, 1500)
        } else {
          setTimeout(() => {
            this.$router.back()
          }, 1500)
        }
      }
    },
    imagePreview (imgs) {
      ImagePreview(imgs)
    },
    getContent () {
      pdfjsLib.GlobalWorkerOptions.workerSrc = "https://img.tjerong.cn/js/pdf.worker.min.js";
      if (this.orderInfo.contractUrl) {
        // https://unpkg.com/pdfjs-dist@2.0.943/cmaps/
        pdfjsLib.getDocument({
          url: this.orderInfo.contractUrl || 'https://test.tjerong.cn/images/cfca/JK20210801000024448.pdf',// 此参数需要设为true 
          cMapUrl: 'https://img.tjerong.cn/cmaps/',
          cMapPacked: true
        }).promise.then(pdf => {
          console.log(pdf, 'pdf')
          this.pdf = pdf
          this.loadPdf(pdf)
        }).catch(err => {
          this.$toast.fail('文件地址请求失败')
        })
      } else {
        this.$toast.fail('文件地址不正确')
      }
    },
    loadPdf (pdf) {
      let vm = this
      try {
        let scale = 2;
        if (vm.pageNum <= pdf.numPages) {
          pdf.getPage(vm.pageNum).then(page => {  // 得到 PDF 文件的第一页
            try {
              let canvas = document.getElementById('pdf-canvas');
              var viewport = page.getViewport({scale});  // 根据 PDF 页面信息得到视图
              var context = canvas.getContext('2d'); // 指定 canvas 画布按 2D 方式渲染
              // 设定画布大小
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              // 设定渲染参数
              var renderContext = {
                canvasContext: context,
                viewport: viewport
              };

              // 渲染页面
              let renderTask = page.render(renderContext)
              renderTask.promise.then(() => {
                let ctx = canvas.getContext('2d');
                let base64Img = canvas.toDataURL();
                vm.imgs.push(base64Img);
                ctx.clearRect(0,0,canvas.height,canvas.height)
                vm.pageNum ++
                vm.loadPdf(pdf)
              });
            } catch (e) {
              vm.$toast.clear()
              console.log(e)
            }
          });
        } else {
          vm.$toast.clear()
          console.log(this.imgs, 'imgs')
        }
      } catch (e) {
        vm.$toast.clear()
      }
    },
    drawImg () {
      let canvas = document.getElementById('pdf-canvas');
      let ctx = canvas.getContext('2d');
      var text = 'test 123';
      // 需要绘制的文本内容
      // ctx.fillStyle = '#f90'; 
      // // 文本颜色
      // ctx.textAlign = 'center'; 
      // // 文本对齐方式 
      // ctx.font = '60px Airal'; 
      ctx.globalAlpha = 0.3;
      ctx.font = "46px 黑体";
      ctx.rotate(-20 * Math.PI / 180);
      ctx.fillStyle = "rgba(12,100,100,0.4)";
      ctx.rotate('Math.PI/60');
      for(let i of [1, 2, 3, 4, 5, 6, 7, 8]) {
        //ctx.fillText(text, i * 10, i * 200);
        ctx.fillText(text+" "+text, -300 - i * 1, i * 200 + 50);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  height: 100vh;
  padding-bottom: 60px;
  padding-bottom: constant(safe-area-inset-bottom); ///兼容 IOS<11.2/
  padding-bottom: env(safe-area-inset-bottom); ///兼容 IOS>11.2/
  .tip {
    color:#ccc;
    font-size: 12px;
    text-align: center;
    position: fixed;
    width: 100%;
    height: 16px;
    line-height:16px;
    top: 10px;
    left: 0;
  }
  .content-box {
    padding-top: 20px;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    canvas {
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
      left: -2000px;
      top: 0;
    }
    img {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
      display: block;
    }
  }
  ._btn {
    width: 346px;
    position: fixed;
    z-index: 99;
    bottom: 20px;
    bottom: constant(safe-area-inset-bottom); ///兼容 IOS<11.2/
    bottom: env(safe-area-inset-bottom); ///兼容 IOS>11.2/
    margin-bottom: 20px;
    left: 50%;
    margin-left: -173px;
  }
}
</style>
